import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";
import dayjs from "dayjs";

import {
  Grid,
  Typography,
  Box,
  Autocomplete,
  TextField,
  FormHelperText,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { Horse } from "../types/Horses";
import KnowledgeableSelect from "../components/KnowledgeableSelect";
import { useDataContext } from "../context/DataProvider";
import { Person } from "../types/People";
import { Lesson } from "../types/Lessons";
import ErrorBanner from "../components/ErrorBanner";
import { useDateAndPageContext } from "../context/DateProvider";
import { Day } from "../types/Day";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function AddRider() {
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { boardData, horseData, loading, fetchData } = useDataContext();
  const { date } = useDateAndPageContext();

  const [lessonDate, setLessonDate] = useState(date.format("YYYY-MM-DD"));
  const [lessonId, setLessonId] = useState(-1);
  const [rider, setRider] = useState<Person | undefined>();

  const [riderType, setRiderType] = useState<
    | "Hack Ride"
    | "Make Up Class"
    | "Eval"
    | "Add From Board"
    | "New Rider"
    | "Move"
    | "Schooling"
    | ""
  >("");
  const [permanentMove, setPermanentMove] = useState<boolean>(false);

  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [riders, setRiders] = useState<Person[]>([]);

  const [newRider, setNewRider] = useState<{
    firstName: string;
    lastName: string;
  }>({
    firstName: "",
    lastName: "",
  });

  async function getRiders() {
    try {
      const result: Person[] = await HttpClient.get("/riders");
      setRiders(result);
    } catch (error) {
      setErrorMessage(
        "Could not load available riders. Please contact Rebecca."
      );
    }
  }

  async function getLessons(lessonDate: string) {
    try {
      const result: Lesson[] = await HttpClient.get(
        "/lessons",
        {},
        {
          date: lessonDate,
        }
      );
      setLessons(result);
    } catch (error) {
      setErrorMessage(
        "Could not load available lessons. Please contact Rebecca."
      );
    }
  }

  async function handleSubmit() {
    setErrorMessage("");

    let request = null;

    if (
      riderType !== "Eval" &&
      riderType !== "New Rider" &&
      riderType !== "Move" &&
      !permanentMove
    ) {
      if (!riderType) {
        setErrorMessage("Please select an add reason");
        return;
      }

      if (!rider || lessonId < 0 || !lessonDate) {
        setErrorMessage(
          "Please select a rider, lesson time and lesson date to submit."
        );
        return;
      }

      request = HttpClient.post(`/board/rider`, {
        rider: rider.id,
        modificationDate: lessonDate,
        lesson: lessonId,
        modificationType: riderType,
      });
    } else if (riderType === "Move" || permanentMove) {
      if (!rider || lessonId < 0 || !lessonDate) {
        setErrorMessage(
          "Please select a rider, lesson time and lesson date to submit."
        );
        return;
      }

      request = HttpClient.post(`/riders/${rider.id}/lessons/${lessonId}`, {});
    } else if (riderType === "Eval" || riderType === "New Rider") {
      if (
        !newRider.firstName ||
        !newRider.lastName ||
        lessonId < 0 ||
        !lessonDate
      ) {
        setErrorMessage(
          "Please give the rider a first and last name and select a lesson time and lesson date to submit."
        );
        return;
      }

      request = HttpClient.post("/people", {
        firstName: newRider.firstName,
        lastName: newRider.lastName,
        startDate: lessonDate,
        rider: true,
        staff: false,
        schooling: false,
        eval: !permanentMove,
        lessonInfo: { currentLessons: [{ lessonId }] },
      });
    }
    try {
      const response = await request;
      console.log(response);
      await fetchData();
      navigate("/");
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  useEffect(() => {
    getRiders();
    getLessons(lessonDate);
  }, [lessonDate]);

  return (
    <Box margin={"1em"}>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Add Rider
        </Typography>
      </Grid>
      <ErrorBanner errorHeading="Error" errorMessage={errorMessage} />
      <Box padding={"1em"}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          rowSpacing={2}
          paddingTop="1em"
          paddingBottom="1em"
          marginLeft="-0.5em"
        >
          <Grid item xs={12}>
            <TextField
              label="Add Reason"
              required
              select
              onChange={(event) => {
                setRiderType(event.target.value as "");
              }}
              value={riderType}
              name="riderType"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Hack Ride">Hack Ride</MenuItem>
              <MenuItem value="Make Up Class">Make Up Class</MenuItem>
              <MenuItem value="Eval">Eval</MenuItem>
              <MenuItem value="New Rider">New Rider at WELCA</MenuItem>
              <MenuItem value="Schooling">Schooling</MenuItem>
              <MenuItem value="Move">Permanent Add to Lesson</MenuItem>
              <MenuItem value="Add From Board">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {riderType !== "Eval" && riderType != "New Rider" ? (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                getOptionKey={(option) => option.id}
                value={rider}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={riders}
                renderInput={(params) => (
                  <TextField {...params} label="Rider" />
                )}
                onChange={(event, selectedOption) => {
                  setRider(selectedOption ?? undefined);
                }}
              />
            ) : (
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    required
                    type="text"
                    value={newRider.firstName}
                    placeholder="First Name"
                    name="firstName"
                    onChange={(event) => {
                      setNewRider({
                        ...newRider,
                        firstName: event.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    required
                    type="text"
                    value={newRider.lastName}
                    placeholder="Last Name"
                    name="lastName"
                    onChange={(event) => {
                      setNewRider({
                        ...newRider,
                        lastName: event.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} columnSpacing={2}>
            <Grid item xs={riderType === "Add From Board" ? 6 : 12}>
              <TextField
                label="Date"
                required
                type="date"
                onChange={(event) => {
                  setLessonDate(event.target.value);
                }}
                value={lessonDate}
                name="lessonDate"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            {riderType === "Add From Board" ? (
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        setPermanentMove(event.target.checked);
                      }}
                      name="permanentChange"
                      checked={permanentMove}
                    />
                  }
                  label="This person is permantly moving to the class"
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <KnowledgeableSelect
              required
              options={lessons
                .filter((l: Lesson) => {
                  if (!lessonDate) {
                    return false;
                  }
                  return l.activeDays.includes(
                    dayString[new Date(lessonDate).getUTCDay()] as Day
                  );
                })
                .map((l) => {
                  return { display: formatTime(l.lessonTime), otherDetails: l };
                })}
              onChange={(lesson: Lesson | undefined) => {
                if (!lesson) {
                  setLessonId(-1);
                  return;
                }

                setLessonId(lesson?.id!);
              }}
              label="Lesson Time"
              initialSelected={-1}
              defaultOption={{
                lessonType: "",
                program: "",
                startDate: "",
                endDate: "",
                activeDays: [],
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <SubmitButton
        handleSubmit={handleSubmit}
        disabled={loading}
        label="Submit"
      />
      <Grid container spacing={2} padding={"1em"}>
        <Button
          variant="contained"
          color="inherit"
          onClick={async () => {
            navigate("/");
            await fetchData();
          }}
          disabled={loading}
          fullWidth
          sx={{ marginLeft: "1em" }}
        >
          Back
        </Button>
      </Grid>
    </Box>
  );
}

export default AddRider;
