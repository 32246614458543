import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { BoardDay } from "../types/Board";
import { Horse, HorseSaddle } from "../types/Horses";
import { HttpClient } from "../helpers/httpClient";
import { useDateAndPageContext } from "./DateProvider";

interface IDataContext {
  boardData: BoardDay | null;
  horseData: Horse[] | null;
  horseSaddleData: {
    [key: string]: HorseSaddle[];
  } | null;
  loading: boolean;
  fetchData: Function;
  numPages: number | undefined;
}

const MAX_RIDERS_PER_PAGE = 18;
const MAX_LESSONS_PER_PAGE = 3;

function determineColums(day: BoardDay) {
  let page = 0;
  let currentNumRiders = 0;
  let currentNumLessons = 1;

  const cols: BoardDay[] = [[]];
  for (let i = 0; i < day?.length; i++) {
    console.log(i, currentNumLessons, MAX_LESSONS_PER_PAGE);
    if (
      currentNumRiders + day[i].entries.length <= MAX_RIDERS_PER_PAGE &&
      currentNumLessons <= MAX_LESSONS_PER_PAGE
    ) {
      currentNumRiders += day[i].entries.length;
      currentNumLessons += 1;
      cols[page].push(day[i]);
      continue;
    }

    currentNumRiders = day[i].entries.length;
    currentNumLessons = 2;
    page += 1;
    cols.push([day[i]]);
  }
  return cols;
}

export const DataContext = createContext<IDataContext | undefined>(undefined);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export function DataProvider(props: { children: ReactNode }) {
  const [boardData, setBoardData] = useState<BoardDay | null>(null);
  const [numPages, setNumPages] = useState<number>();
  const [horseData, setHorseData] = useState<Horse[] | null>(null);
  const [horseSaddleData, setHorseSaddleData] = useState<{
    [key: string]: HorseSaddle[];
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { date } = useDateAndPageContext();

  const dayString = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fetchData = async () => {
    try {
      const result: Horse[] = await HttpClient.get(`/horses`);
      console.log(result);
      setHorseData(result);

      const result2: BoardDay = await HttpClient.get(
        `/board/day/${dayString[date.day()]}`,
        {},
        {
          date: date.format("YYYY-MM-DD"),
        }
      );
      setBoardData(result2);
      let cols = determineColums(result2);
      setNumPages(cols.length);

      const result3: { [key: string]: HorseSaddle[] } = await HttpClient.get(
        `/horses/saddles`
      );
      console.log(result3);
      setHorseSaddleData(result3);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [date]);

  return (
    <DataContext.Provider
      value={{
        horseData,
        boardData,
        horseSaddleData,
        loading,
        fetchData,
        numPages,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
