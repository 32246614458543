import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";

import { Grid, Typography, Box, Checkbox } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { useDataContext } from "../context/DataProvider";
import { publish } from "../websockets/Websockets";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { useDateAndPageContext } from "../context/DateProvider";

function HorseFlags() {
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { boardData, horseData, loading, fetchData } = useDataContext();
  const { client, loadingWeb } = useWebsocketContext();
  const { date } = useDateAndPageContext();

  const [data, setData] = useState<{ [key: string]: boolean }>({});
  const [feedData, setFeedData] = useState<{ [key: string]: boolean }>({});

  async function handleHorseChange(
    horseIn: boolean,
    boardId: string,
    lessonId: string,
    currentDate: string
  ) {
    try {
      if (client) {
        publish(
          client,
          JSON.stringify({
            type: "horseInChange",
            data: {
              boardId,
              lessonId,
              horseIn,
            },
          })
        );
      }

      const result = await HttpClient.put(
        `/board/${boardId}/date/${currentDate}/horseIn`,
        {
          horseIn,
        }
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  async function handleHorseFeedChange(
    feedAfterClass: boolean,
    boardId: string,
    lessonId: string,
    currentDate: string
  ) {
    try {
      if (client) {
        publish(
          client,
          JSON.stringify({
            type: "horseSpecialFeedChange",
            data: {
              lesson: lessonId,
              boardId,
              feedAfterClass,
            },
          })
        );
      }

      const result = await HttpClient.put(
        `/board/${boardId}/date/${currentDate}/specialFeed`,
        {
          feedAfterClass,
        }
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Horse In and Special Feed
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        {boardData?.map((lessonTime, index1) => (
          <Box key={index1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                paddingTop: "1em",
              }}
            >
              {formatTime(lessonTime.lessonTime)}
            </Typography>

            <Grid
              container
              item
              columnSpacing={2}
              paddingTop="0.5em"
              paddingBottom="0.5em"
              marginLeft="-0.5em"
            >
              <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                Horse
              </Grid>
              <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                Horse Has Been Brought In
              </Grid>
              <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                Horse Has Special Feed
              </Grid>
            </Grid>

            {lessonTime.entries.map((entry, index2) => {
              if (!entry.horseName) {
                return null;
              }

              return (
                <Grid
                  item
                  container
                  key={index2}
                  sx={{
                    backgroundColor: index2 % 2 === 0 ? "#f5f5f5" : "white",
                  }}
                  alignItems="center"
                  justifyContent="center"
                  columnSpacing={2}
                  paddingTop="1em"
                  paddingBottom="1em"
                  marginLeft="-0.5em"
                >
                  <Grid item xs={4}>
                    {entry.horseName}
                  </Grid>
                  <Grid item xs={4}>
                    <Checkbox
                      checked={
                        data[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] !== undefined
                          ? data[
                              entry.personId +
                                " " +
                                lessonTime.lessonId.toString()
                            ]
                          : entry.horseIn
                      }
                      onChange={(event: any) => {
                        let newData = {
                          ...data,
                        };
                        newData[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] = event.target.checked;
                        setData(newData);

                        handleHorseChange(
                          event.target.checked,
                          entry.boardId,
                          lessonTime.lessonId.toString(),
                          date.format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Checkbox
                      checked={
                        feedData[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] !== undefined
                          ? feedData[
                              entry.personId +
                                " " +
                                lessonTime.lessonId.toString()
                            ]
                          : entry.feedAfterClass
                      }
                      onChange={(event: any) => {
                        let newData = {
                          ...feedData,
                        };
                        newData[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] = event.target.checked;
                        setFeedData(newData);

                        handleHorseFeedChange(
                          event.target.checked,
                          entry.boardId,
                          lessonTime.lessonId.toString(),
                          date.format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        ))}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading2}
        label="Back"
      />
    </div>
  );
}

export default HorseFlags;
